<template>
    <el-upload class="upload-demo" :on-remove="handleRemove" :http-request="upload" action="" :on-change="handleChange"
        :file-list="fileList">
        <el-button class="upload" size="small" icon="el-icon-plus">点击上传AI模板</el-button>
    </el-upload>
</template>

<script>
    import SparkMD5 from "spark-md5";
    let OSS = require('ali-oss')
    export default {
        data() {
            return {
                fileList: [],
                client: null,
                uploadStatus: null, // 进度条上传状态
                type: null,
                location: null,
                options: {
                    chunkNumber: null, //当前文件块，从1开始
                    chunkSize: 10 * 1024 * 1024, //分块大小
                    currentChunkSize: null, //当前分块大小
                    totalSize: null, //总大小
                    identifier: null, //文件标识
                    currentIdentifier: null, //当前文件标识
                    filename: null, //文件名
                    relativePath: null, //文件路径（阿里云路径）
                    totalChunks: null, //总块数
                    type: null, //文件类型
                    chunkData: null //文件数据
                },
                currentFile: {},
            }

        },
        props: {
            list: {
                type: Array
            },
        },
        watch: {
            list: {
                handler(newVal, oldVal) {
                    // console.log('newVal',newVal)
                    // console.log('oldVal',oldVal)
                    if(newVal) {
                        this.fileList = newVal;
                    }
                },
                immediate: true,
            }
        },
        mounted() {
            this.getQueryToken();
        },
        methods: {
            upload() {
                console.log("upload");
            },
            handleChange(file, fileList) {
                this.currentFile = file;
                this.computeMD5(file.raw, file)
            },
            handleRemove(file) {
                console.log('删除的', file);
                let idx = undefined;
                for (let index = 0; index < this.fileList.length; index++) {
                    if (this.fileList[index].identifier == file.identifier) {
                        idx = index;
                        break;
                    }
                }
                this.fileList.splice(idx, 1)
                // console.log('list',this.fileList);
                let md5List = [];
                this.fileList.map(item => {
                    md5List.push(item.identifier)
                })
                this.$emit('uploadFile', md5List);

                // this.$emit('uploadFile', this.fileList);

            },
            async getQueryToken() {
                let resData = await this.$Api.OssUpload.getQueryToken()
                this.client = new OSS({
                    // secure:true,
                    region: 'oss-cn-beijing', //根据那你的Bucket地点来填写
                    accessKeyId: resData.data.accessKeyId, //自己账户的accessKeyId
                    accessKeySecret: resData.data.accessKeySecret, //自己账户的accessKeySecret
                    bucket: resData.data.bucket, //bucket名字
                    stsToken: resData.data.stsToken
                });

            },
            computeMD5(fileRaw, file) {
                let that = this;
                let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
                let chunkSize = 10 * 1024 * 1024
                let chunks = Math.ceil(file.size / chunkSize)
                let currentChunk = 0
                let spark = new SparkMD5.ArrayBuffer()
                let fileReader = new FileReader()

                fileReader.onload = function (e) {
                    spark.append(e.target.result)
                    currentChunk++
                    if (currentChunk < chunks) {
                        loadNext()
                        //         console.log(
                        //             `第${currentChunk}分片解析完成, 开始第${currentChunk +
                        // 1} / ${chunks}分片解析`
                        //         );
                    } else {
                        let md5 = spark.end(); //得到md5
                        function getCaption(obj) {
                            var index = obj.lastIndexOf(".");
                            obj = obj.substring(index + 1, obj.length);
                            return obj;
                        }
                        let filemd5 = md5 + "_" + file.size + "_" + getCaption(file.name);
                        that.options.currentIdentifier = md5;
                        that.options.identifier = filemd5;
                        that.options.totalSize = file.size;
                        that.options.filename = file.name;
                        that.options.totalChunks = currentChunk;
                        that.options.type = getCaption(file.name);
                        // that.getCheck(filemd5, file)
                        // console.log('computed hash', filemd5)
                        that.handleHttpRequest(file.raw)
                    }
                }
                fileReader.onerror = function () {
                    console.warn('FileReader error.')
                }

                function loadNext() {
                    let start = currentChunk * chunkSize
                    let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
                    // 注意这里的 fileRaw
                    fileReader.readAsArrayBuffer(blobSlice.call(fileRaw, start, end))
                }
                loadNext()
            },
            //简单上传 
            async handleHttpRequest(file) { //上传OSS
                // console.log('++++++', file)
                try {
                    // 简单上传文件
                    let filePath = 'upload/' + this.dayjs(parseInt(new Date().getTime())).format('YYYY/MM/DD') +
                        '/' + this
                        .options.identifier + '/' + this.options.filename;
                    const result = await this.client.put(filePath, file)
                    this.type = file.type;
                    // console.log('123', result)
                    this.postMerge(this.options, filePath, file)
                } catch (e) {
                    console.error(e);
                    if (e.code === 'ConnectionTimeoutError') { // 请求超时异常处理
                        this.uploadStatus = 'exception'
                        // console.log("TimeoutError");
                    }
                }
            },
            async postMerge(options, filePath, file) {
                let data = {
                    filename: options.filename, //文件名
                    identifier: options.identifier, //文件标识
                    totalSize: options.totalSize, //总大小
                    type: this.type, //请求类型
                    location: filePath, //文件路径（阿里云路径
                    ext: options.type, //文件类型
                    projectId: this.$store.state.user.currentProject.id,
                    // sourceType: this.sourceType
                }
                let resData = await this.$Api.OssUpload.postMerge(data)
                this.CompleteInfo(file)
            },
            async CompleteInfo(file) {
                console.log('file2:', file)
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
                let data = {
                    file: file,
                    identify: this.options.identifier,
                }
                let resData = await this.$Api.kimi.uploadFileToKiMi(data);
                // console.log('上传文件到kimi结果:', resData)
                this.$set(this.currentFile, 'identifier', this.options.identifier);
                this.fileList.push(this.currentFile);
                let md5List = [];
                this.fileList.map(item => {
                    md5List.push(item.identifier)
                })
                this.$emit('uploadFile', md5List);

            },
        },

    }
</script>

<style lang="less" scoped>
    .upload-demo {
        width: 650px;
        margin-bottom: 20px;
    }
</style>